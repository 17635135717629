import { Component, JSXElement } from 'solid-js';
import { Properties } from 'solid-js/web';

import styles from './ScreenCenter.module.scss';

const ScreenCenter: Component<{ children?: JSXElement }> = (props) => {

  return (
    <div class={styles.screenCenter}>
      {props.children}
    </div>
  );
}

export default ScreenCenter;
