import { NostrExtension, NostrRelayEvent, NostrRelaySignedEvent, NostrWindow } from "./types.d";


type QueueItem = {
  action: () => Promise<any>,
  resolve: (result: any) => void,
  reject: (reason: any) => void,
};

class Queue {
  _items: QueueItem[];
  _pendingPromise: boolean;

  constructor() {
    this._items = [];
    this._pendingPromise = false;
  }

  enqueue<T>(action: () => Promise<T>) {
    return new Promise<T>((resolve, reject) => {
      this._items.push({ action, resolve, reject });
      this.dequeue();
    });
  }

  async dequeue() {
    if (this._pendingPromise) return false;

    let item = this._items.shift();

    if (!item) return false;

    try {
      this._pendingPromise = true;

      let payload = await item.action();

      this._pendingPromise = false;
      item.resolve(payload);
    } catch (e) {
      this._pendingPromise = false;
      item.reject(e);
    } finally {
      this.dequeue();
    }

    return true;
  }

  get size() {
    return this._items.length;
  }
}

const eventQueue = new Queue();

const enqueue = async <T>(action: (nostr: NostrExtension) => Promise<T>) => {
  const win = window as NostrWindow;
  const nostr = win.nostr;

  if (nostr === undefined) {
    throw('no_nostr_extension');
  }

  return await eventQueue.enqueue<T>(() => action(nostr));
}

export const signEvent = async (event: NostrRelayEvent) => {
  try {
    return await enqueue<NostrRelaySignedEvent>(async (nostr) => {
      try {
        return await nostr.signEvent(event);
      } catch(reason) {
        throw(reason);
      }
    })
  } catch (reason) {
    throw(reason);
  }
};

export const getPublicKey = async () => {
  try {
    return await enqueue<string>(async (nostr) => {
      try {
        return await nostr.getPublicKey();
      } catch(reason) {
        throw(reason);
      }
    });
  } catch (reason) {
    throw(reason);
  }
};
