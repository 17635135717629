import { satsInBTC } from "./constants";
import { sendMessage } from "./socketsWallet";
import { Kind, SupportedCurrencies, PaymentDetailInfo, ReceiveDetails, NostrRelayEvent } from "./types.d";
import { bech32 } from "@scure/base";
// @ts-ignore Bad types in nostr-tools
import { utils } from "nostr-tools";
import { signEvent } from "./nostrAPI";

const sendToWallet = async (event: NostrRelayEvent, subId: string, apiEndpoint?: string) => {
  try {
    const signedEvent = await signEvent(event);

    sendMessage(JSON.stringify([
      "REQ",
      subId,
      {cache: [apiEndpoint || "wallet", { operation_event: signedEvent }]},
    ]));
  } catch (reason) {
    console.log('failed to sign due to: ', reason);
  }

}

export const getUserPremissions = (pubkey: string | undefined, subId: string) => {
  if (!pubkey) {
    return;
  }

  const content = JSON.stringify(
    ["is_user", { pubkey }],
  );

  const event = {
    content,
    kind: Kind.WALLET_OPERATION,
    created_at: Math.ceil((new Date()).getTime() / 1000),
    tags: [],
  };

  sendToWallet(event, subId);
}
export const startMonitoring = (pubkey: string | undefined, subId: string) => {
  if (!pubkey) {
    return;
  }

  const content = JSON.stringify(
    { subwallet: 1},
  );

  const event = {
    content,
    kind: Kind.WALLET_OPERATION,
    created_at: Math.ceil((new Date()).getTime() / 1000),
    tags: [],
  };

  sendToWallet(event, subId, 'wallet_monitor');
}

export const getWalletBalance = (pubkey: string | undefined, subId: string) => {
  if (!pubkey) {
    return;
  }

  const content = JSON.stringify(
    ["balance", { subwallet: 1 }],
  );

  const event = {
    content,
    kind: Kind.WALLET_OPERATION,
    created_at: Math.ceil((new Date()).getTime() / 1000),
    tags: [],
  };

  sendToWallet(event, subId);
}

export const getTransactionHistory = (pubkey: string | undefined, subId: string, since = 0, until = 0, limit = 20, offset = 0) => {
  if (!pubkey) {
    return;
  }

  let payload: any = { subwallet: 1, limit };

  if (until > 0) payload.until = until;

  if (offset > 0) payload.offset = offset;

  const content = JSON.stringify(
    ["transactions", payload],
  );

  const event = {
    content,
    kind: Kind.WALLET_OPERATION,
    created_at: Math.ceil((new Date()).getTime() / 1000),
    tags: [],
  };

  sendToWallet(event, subId);
}

export const getExchangeRate = (pubkey: string | undefined, subId: string, currency: SupportedCurrencies) => {
  if (!pubkey) {
    return;
  }

  const content = JSON.stringify(
    ["exchange_rate", { target_currency: currency }],
  );

  const event = {
    content,
    kind: Kind.WALLET_OPERATION,
    created_at: Math.ceil((new Date()).getTime() / 1000),
    tags: [],
  };

  sendToWallet(event, subId);
}

export const withdraw = (pubkey: string | undefined, subId: string, address: string, details: PaymentDetailInfo) => {
  if (!pubkey) {
    return;
  }

  const amount_btc = (((parseFloat(details.amount) || 0) * details.rateToSats) / satsInBTC).toFixed(20);

  const content = JSON.stringify(
    ["withdraw", {
      subwallet: 1,
      target_lud16: address,
      amount_btc,
      note_for_recipient: details.messageToRecipient,
      note_for_self: details.messageToSelf,
    }],
  );

  const event = {
    content,
    kind: Kind.WALLET_OPERATION,
    created_at: Math.ceil((new Date()).getTime() / 1000),
    tags: [],
  };

  sendToWallet(event, subId);
}

export const withdrawLnbc = (pubkey: string | undefined, subId: string, address: string, details: PaymentDetailInfo) => {
  if (!pubkey) {
    return;
  }

  const content = JSON.stringify(
    ["withdraw", {
      subwallet: 1,
      lnInvoice: address,
      target_lud16: '',
      note_for_recipient: details.messageToRecipient,
      note_for_self: details.messageToSelf,
    }],
  );

  const event = {
    content,
    kind: Kind.WALLET_OPERATION,
    created_at: Math.ceil((new Date()).getTime() / 1000),
    tags: [],
  };

  sendToWallet(event, subId);
}

export const deposit = (pubkey: string | undefined, subId: string, details: ReceiveDetails, rateToSats?: number) => {
  if (!pubkey) {
    return;
  }

  let payload: { subwallet: number, amount_btc?: string, description?: string } = {
    subwallet: 1,
  };

  if (details.amount && rateToSats) {
    payload.amount_btc = (((parseFloat(details.amount) || 0) * rateToSats) / satsInBTC).toFixed(20);
  }

  if (details.description) {
    payload.description = details.description
  }

  const content = JSON.stringify(
    ["deposit", { ...payload }],
  );

  const event = {
    content,
    kind: Kind.WALLET_OPERATION,
    created_at: Math.ceil((new Date()).getTime() / 1000),
    tags: [],
  };

  sendToWallet(event, subId);
}

type LUDInfo = {
  status?: string,
  tag?: string,
  callback?: string,
  metadata?: string[][],
  minSendable?: number,
  maxSendable?: number,
  pr: string,
};

export const getAddressInfo = async (lud16?: string, lud06?: string) => {

  const ludInfo: LUDInfo | null = await getLudInfo(lud16, lud06);

  if (!ludInfo || !ludInfo.callback) {
    return false;
  }

  const r2: { pr: string } = await (await fetch(`${ludInfo.callback}?amount=1000`)).json();

  return { ...r2, ...ludInfo } as LUDInfo;
}

export const getLudInfo = async (lud16?: string, lud06?: string ): Promise<LUDInfo | null>  => {
  try {
    let lnurl: string = '';

    if (lud16) {
      let [name, domain] = lud16.split('@')
      lnurl = `https://${domain}/.well-known/lnurlp/${name}`
    }
    else if (lud06) {
      let {words} = bech32.decode(lud06, 1023)
      let data = bech32.fromWords(words)
      lnurl = utils.utf8Decoder.decode(data)
    }
    else {
      return null;
    }

    let res = await fetch(lnurl)
    let body = await res.json()

    return body;
  } catch (err) {
    console.log('E: ', err);
    return null;
    /*-*/
  }

  return null;
}
