import { JSX } from "solid-js/types/jsx";

export type NostrRelay = { read: boolean, write: boolean };

export type NostrRelays = Record<string, NostrRelay>;

export type NostrRelayEvent = {
  kind: number,
  content: string,
  created_at: number,
  tags?: string[][],
};
export type NostrRelaySignedEvent = NostrRelayEvent & {
  id: string,
  pubkey: string,
  sig: string,
};

interface SendPaymentResponse {
  preimage: string;
}

export type NostrExtension = {
  getPublicKey: () => Promise<string>,
  getRelays: () => Promise<NostrRelays>,
  signEvent: (event: NostrRelayEvent) => Promise<NostrRelaySignedEvent>,
  nip04: {
    encrypt: (pubkey: string, message: string) => Promise<string>,
    decrypt: (pubkey: string, message: string) => Promise<string>,
  },
};

export type WebLnExtension = {
  enable: () => Promise<void>,
  sendPayment: (req: string) => Promise<SendPaymentResponse>;
};

export type NostrWindow = Window & typeof globalThis & {
  nostr?: NostrExtension,
  webln?: WebLnExtension,
  walletStore: any,
};

export type ContextChildren =
  number |
  boolean |
  Node |
  JSX.ArrayElement |
  (string & {}) |
  null |
  undefined;

export type Currency = {
  symbol: string,
  name: string,
  shorthand: string,
};

export type WalletState = {
  amount: number,
  currency: Currency,
};

export type Amount = {
  amount: number,
  currency: Currency,
};

export type NostrEventType = "EVENT" | "EOSE" | "NOTICE";

export type NostrEventContent ={
  kind: number,
  content: string,
  created_at: number,
  pubkey?: string,
  id: string,
  tags?: string[][],
};


export type NostrEvent = [
  type: "EVENT",
  subkey: string,
  content: NostrEventContent,
];

export type NostrEOSE = [
  type: "EOSE",
  subkey: string,
];

export type NostrNotice = [
  type: "NOTICE",
  subkey: string,
  reason: string,
];

export enum Kind {
  METADATA = 0,
  USER_STATS = 10_000_105,
  PAGE_RANGE = 10_000_113,
  MEDIA_INFO = 10_000_119,

  WALLET_OPERATION = 10_000_300,
  WALLET_BALANCE = 10_000_301,
  WALLET_DEPOSIT_INVOICE = 10_000_302,
  WALLET_DEPOSIT_LNURL = 10_000_303,
  WALLET_TRANSACTIONS = 10_000_304,
  WALLET_EXCHANGE_RATE = 10_000_305,
  WALLET_IS_USER = 10_000_306,
}

export enum KYC {
  MISSING = -1,
  UNKNOWN = 0,
  EMAIL = 1,
  KNOWN = 2,
}

export enum SupportedCurrencies {
  USD = 'USD',
  SATS = 'SATS',
  BTC = 'BTC',
}

export type TxType =
  'DEPOSIT' |
  'WITHDRAW' |
  'TRANSFER' |
  'ZAP' |
  'TOP_UP' |
  'PAYOUT' |
  'CLEARING_USD' |
  'CLEARING_BTC';

export type TxState =
  'CREATED' |
  'PROCESSING' |
  'SUCCEEDED' |
  'FAILED' |
  'CANCELED';

export type Transaction = {
  id: string,
  type: TxType,
  state: TxState,
  created_at: number,
  completed_at?: number | null,
  amount_btc: string | null,
  amount_usd: string | null,
  pubkey_1: string | null,
  lud16_1: string | null,
  subindex_1: "ZAPPING",
  pubkey_2?: string | null,
  lud16_2?: string | null,
  subindex_2?: "ZAPPING",
  note: string | null
  is_zap: boolean,
}

export type PaymentDetailInfo = {
  amount: string,
  rateToSats: number,
  messageToRecipient: string,
  messageToSelf: string,
}

export type PartialPaymentDetailInfo = {
  amount?: string,
  rateToSats?: number,
  messageToRecipient?: string,
  messageToSelf?: string,
}

export type UserProfile = {
  about?: string,
  banner?: string,
  created_at?: string,
  displayName?: string
  display_name?: string,
  lud16?: string,
  name?: string,
  nip05?: string,
  npub: string,
  picture?: string,
  pubkey: string,
  website?: string,
};

export type ReceiveDetails = {
  description?: string,
  amount?: string,
};

export type MediaSize = 'o' | 's' | 'm' | 'l';

export type MediaVariant = {
  s: MediaSize,
  a: 0 | 1,
  w: number,
  h: number,
  mt: string,
  media_url: string,
};

export type MediaEvent = {
  event_id: string,
  resources: { url: string, variants: MediaVariant[] }[],
};

export type PageRange = {
  since: number,
  until: number,
  order_by: string,
};

export type WalletBalance = {
  amount: string,
  currency: string,
};
