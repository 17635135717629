import { createSignal } from "solid-js";
import { NostrEvent, NostrEOSE, NostrEventType, NostrEventContent, NostrNotice } from "./types";

export const [socket, setSocket] = createSignal<WebSocket>();

export const [isConnected, setConnected] = createSignal<Boolean>(false);

export const isNotConnected = () => !isConnected();

const onOpen = () => {
  setConnected(true);
}

const onClose = () => {
  setConnected(false);

  socket()?.removeEventListener('open', onOpen);
  socket()?.removeEventListener('close', onClose);
  socket()?.removeEventListener('error', onError);

  setTimeout(() => {
    connect();
  }, 200);
}

const onError = (error: Event) => {
  console.log("ws error: ", error);
};

export let walletServer = '';

export const connect = async () => {
  if (isNotConnected()) {

    try {
      const override = new URL(localStorage.getItem('walletServer') || '');

      walletServer = override.href;
    } catch (e) {
      walletServer = import.meta.env.PRIMAL_WALLET_URL;

      try {
        const res = await fetch('https://primal.net/.well-known/primal-endpoints.json');

        const ep = await res.json();

        walletServer = ep.wallet_server_v1 || walletServer;
      } catch(e) {
        console.warn('Unable to get well-known endpoints, falling back to defaults');
      }
    }

    setSocket(new WebSocket(walletServer));
    console.log('WALLET SOCKET: ', socket());

    socket()?.addEventListener('open', onOpen);
    socket()?.addEventListener('close', onClose);
    socket()?.addEventListener('error', onError);
  }
};

export const disconnect = () => {
  socket()?.close();
};

export const reset = () => {
  disconnect();
  setTimeout(connect, 1000);
};

export const sendMessage = (message: string) => {
  isConnected() && socket()?.send(message);
}

export const refreshSocketListeners = (
  ws: WebSocket | undefined,
  listeners: Record<string, (event: any) => any>,
  ) => {

  if (!ws) {
    return;
  }

  Object.keys(listeners).forEach((event: string) => {
    ws.removeEventListener(event, listeners[event]);
    ws.addEventListener(event, listeners[event]);
  });
};

export const removeSocketListeners = (
  ws: WebSocket | undefined,
  listeners: Record<string, (event: any) => any>,
  ) => {

  if (!ws) {
    return;
  }

  Object.keys(listeners).forEach((event: string) => {
    ws.removeEventListener(event, listeners[event]);
  });
};

export const subscribeTo = (
  subId: string,
  handlers?: {
    onEvent?: (subId: string, content: NostrEventContent) => void,
    onNotice?: (subId: string, reason: string) => void,
    onEose?: (subId: string) => void,
  },
) => {
  const listener = (event: MessageEvent) => {
    const message: NostrEvent | NostrEOSE | NostrNotice = JSON.parse(event.data);
    const [type, subscriptionId] = message;

    if (handlers && subId === subscriptionId) {
      if (type === 'EVENT') {
        handlers.onEvent && handlers.onEvent(subscriptionId, message[2]);
      }

      if (type === 'EOSE') {
        handlers.onEose && handlers.onEose(subscriptionId);
      }

      if (type === 'NOTICE') {
        handlers.onNotice && handlers.onNotice(subscriptionId, message[2])
      }
    }

  };

  socket()?.addEventListener('message', listener);

  return () => {
    socket()?.removeEventListener('message', listener);
  };
};
