import { createStore } from "solid-js/store";
import {
  createContext,
  createEffect,
  onMount,
  useContext
} from "solid-js";
import { satoshi, USD, exchangeRates, APP_ID, satsInBTC, floatingPoints, currencies } from "../constants";
import { Currency, Amount, ContextChildren, NostrWindow, KYC, SupportedCurrencies, Transaction, PaymentDetailInfo, Kind, UserProfile, ReceiveDetails, MediaEvent, MediaVariant, MediaSize, PageRange, WalletBalance } from "../types.d";
import { subscribeTo as subscribeToWallet } from "../socketsWallet";
import { subscribeTo as subscribeToCache } from "../socketsCache";
import { deposit, getExchangeRate, getTransactionHistory, getUserPremissions, getWalletBalance, startMonitoring, withdraw, withdrawLnbc } from "../walletAPI";
import { getUserMetadata, getUserProfiles } from "../cacheAPI";
import { getPublicKey } from "../nostrAPI";

export type AccountContextStore = {
  balance: number, // always in satoshi
  primaryCurrency: Currency,
  secondaryCurrency: Currency,
  isKeyLookupDone: boolean,
  pubkey: string | undefined,
  kyc: KYC, // Know Your Customer Level
  userProfile: UserProfile | undefined,
  receiveDetails: ReceiveDetails,
  receiveData: string,
  primaryAmount: () => Amount,
  secondaryAmount: () => Amount,
  balanceForDisplay: () => string,
  currencyExchage: (amount: number, from: Currency, to: Currency) => Amount,
  getAvatar: (url: string | undefined) => string | undefined,
  exchangeRate: Record<string, Record<string, number>>,
  transactionHistory: Transaction[],
  transactionHistoryRange: PageRange,
  isFetchingTransactions: boolean,
  knownUsers: Record<string, UserProfile>,
  media: Record<string, MediaVariant[]>,
  changeMonitorSwitch: boolean,
  actions: {
    reverseCurrencies: () => void,
    getTransactions: (pubkey: string | undefined, limit: number | undefined, nextPage?: boolean) => void,
    sendPayment: (address: string, details: PaymentDetailInfo, then?: (success: boolean) => void) => void,
    updateReceiveDetails: (details: ReceiveDetails) => void,
    doDeposit: (then?: () => void) => void,
    resetReceiveData: () => void,
    clearTransactions: () => void,
    setIsTransactionFetching: (flag: boolean) => void,
    startMonitoringForUpdates: () => void,
    stopMonitoringForUpdates: () => void,
  },
}

export const initialData = {
  balance: 0,
  primaryCurrency: satoshi,
  secondaryCurrency: USD,
  isKeyLookupDone: false,
  pubkey: undefined,
  kyc: KYC.MISSING,
  userProfile: undefined,
  receiveDetails: {},
  receiveData: '',
  knownUsers: {},
  media: {},
  // from: to
  exchangeRate: {
    USD: {
      sats: 1,
      USD: 1,
      BTC: 1,
    },
    sats: {
      USD: 1,
      sats: 1,
      BTC: 1 / satsInBTC
    },
    BTC: {
      USD: 1,
      sats: satsInBTC,
      BTC: 1,
    }
  },
  transactionHistory: [],
  transactionHistoryRange: { since: 0, until: 0, order_by: 'created_at' },
  isFetchingTransactions: true,
  changeMonitorSwitch: false,
};

const formatAmount = (amount: string) => Intl.NumberFormat('en').format(parseFloat(amount));

export const AccountContext = createContext<AccountContextStore>();

export const AccountProvider = (props: { children: ContextChildren }) => {

  let extensionAttempt = 0;
  const extensionAttemptLimit = 1;

  let pubkeyAttempt = 0
  const pubkeyAttemptLimit = 5;

  const currencyExchage: (amount: number, from: Currency, to: Currency) => Amount  = (amount, from, to) => {
    const rate = store.exchangeRate[from.shorthand][to.shorthand];

    if (!rate) {
      return { amount, currency: from };
    }

    return { amount: amount * rate, currency: to };
  }

  const primaryAmount: () => Amount = () => {
    return currencyExchage(store.balance, satoshi, store.primaryCurrency);
  }

  const secondaryAmount: () => Amount = () => {
    return currencyExchage(store.balance, satoshi, store.secondaryCurrency);
  }

  const balanceForDisplay = () => {
    const b = primaryAmount();
    return `${b.currency.symbol}${formatAmount(b.amount.toFixed(floatingPoints[b.currency.shorthand]))} ${b.currency.shorthand}`;
  }

  const getMedia = (url: string, size?: MediaSize , animated?: boolean) => {
    const variants: MediaVariant[] = store.media[url] || [];

    const isOfSize = (s: MediaSize) => size ? size === s : true;
    const isAnimated = (a: 0 | 1) => animated !== undefined ? animated === !!a : true;

    return variants.find(v => isOfSize(v.s) && isAnimated(v.a));
  };

  const getAvatar = (url: string | undefined) => {
    if (!url) {
      return;
    }

    const media = getMedia(url, 's', true);

    return media?.media_url;
  };

// ACTIONS --------------------------------------

const reverseCurrencies = () => {
  const pC = { ...store.primaryCurrency };
  const sC = { ...store.secondaryCurrency };

  updateStore(() => ({ primaryCurrency: sC, secondaryCurrency: pC  }));
};

const setPubkey = (pubkey?: string) => {
  updateStore('pubkey', () => pubkey || undefined);
  pubkey ? localStorage.setItem('pubkey', pubkey) : localStorage.removeItem('pubkey');
  updateStore('isKeyLookupDone', true);
};

const clearTransactions = () => {
  updateStore('transactionHistory', () => []);
  updateStore('transactionHistoryRange', () => ({ since: 0, until: 0, order_by: 'created_at' }));
}

const setIsTransactionFetching = (flag: boolean) => {
  updateStore('isFetchingTransactions', () => flag);
};

const fetchNostrKey = async () => {
  const win = window as NostrWindow;
  const nostr = win.nostr;

  updateStore('isKeyLookupDone', false);

  if (nostr === undefined) {
    console.log('No WebLn extension');
    // Try again after one second if extensionAttempts are not exceeded
    if (extensionAttempt < extensionAttemptLimit) {
      extensionAttempt += 1;
      setTimeout(fetchNostrKey, 1000);
      return;
    }

    setPubkey();
    return;
  }

  try {
    const key = await getPublicKey();

    if (key === undefined && pubkeyAttempt < pubkeyAttemptLimit) {
      setTimeout(fetchNostrKey, 200 + 200 * pubkeyAttempt);
    }
    else {
      setPubkey(key);
    }
  } catch (e: any) {
    setPubkey();
    console.log('error fetching public key: ', e);
  }
}

const fetchExchangeRate = () => {
  const subId = `ex_${APP_ID}`;

  const unsub = subscribeToWallet(subId, {
    onEvent: (_, content) => {
      const response: { rate: string } = JSON.parse(content.content);

      const BTCForTarget = parseFloat(response.rate) || 1;

      const satsToTarget = BTCForTarget / satsInBTC;
      const targetToBTC = 1 / BTCForTarget;
      const targetToSats = 1 / satsToTarget;

      updateStore('exchangeRate', () => ({
        USD: {
          sats: targetToSats,
          BTC: targetToBTC,
          USD: 1,
        },
        sats: {
          sats: 1,
          USD: satsToTarget,
          BTC: 1 / satsInBTC,
        },
        BTC: {
          sats: satsInBTC,
          USD: BTCForTarget,
          BTC: 1,
        }
      }))
    },
    onNotice: (_, reason) => {
      console.warn('Falied to fetch exchange rate: ', reason)
    },
    onEose: (_) => {
      unsub();
    },
  });

  getExchangeRate(store.pubkey, subId, SupportedCurrencies.USD);
}

const updateReceiveDetails = (details: ReceiveDetails) => {
  const update = {
    amount: details.amount && details.amount.length > 0 ? details.amount : undefined,
    description: details.description && details.description.length > 0 ? details.description : undefined,
  }

  updateStore('receiveDetails', () => ({ ...update }));
};

const resetReceiveData = () => {
  updateStore('receiveData', () => '');
}

const storeTransactionPartners = () => {
  let partners = new Set<string>();

  for (let i=0; i < store.transactionHistory.length;i++) {
    const tx = store.transactionHistory[i];

    if (tx.pubkey_2) {
      partners.add(tx.pubkey_2);
    }
  }

  const subId = `tp_${APP_ID}`;

  const unsub = subscribeToCache(subId, {
    onEvent: (_, response) => {
      const { content, kind, pubkey } = response;

      if (kind === Kind.MEDIA_INFO) {
        const mediaInfo: MediaEvent = JSON.parse(content);

        let media: Record<string, MediaVariant[]> = {};

        for (let i = 0;i<mediaInfo.resources.length;i++) {
          const resource = mediaInfo.resources[i];
          media[resource.url] = resource.variants;
        }

        updateStore('media', () => ({ ...media }));
      }

      if (kind === Kind.METADATA) {
        const profile = JSON.parse(content || '{}') as UserProfile;

        if (!pubkey) {
          return;
        }

        updateStore('knownUsers', () => ({ [pubkey]: { ...profile, pubkey } }));
      }
    },
    onNotice: (_, reason) => {
      console.warn('Falied to fetch user profiles: ', reason)
    },
    onEose: (_) => {
      unsub();
      updateStore('isFetchingTransactions', () => false);
    },
  });

  getUserProfiles([...partners], subId);
};

const doDeposit = (then?: () => void) => {
    const subId = `de_${APP_ID}`;

    const unsub = subscribeToWallet(subId, {
      onEvent: (_, response) => {
        const { content, kind } = response;

        if (kind === Kind.WALLET_DEPOSIT_LNURL) {
          if (typeof content === 'object') {
            // @ts-ignore
            updateStore('receiveData', () => content.lnurl);
            return;
          }

          if (typeof content === 'string') {
            const response = JSON.parse(content || '{}') as { lnurl: string };

            updateStore('receiveData', () => response.lnurl);
            return;
          }
        }

        if (kind === Kind.WALLET_DEPOSIT_INVOICE) {
          const i = JSON.parse(content || '{}') as { lnInvoice: string };

          updateStore('receiveData', () => i.lnInvoice)
          return;
        }

      },
      onNotice: (_, reason) => {
        console.warn('Falied to get user profile: ', reason);
      },
      onEose: (_) => {
        unsub();
        then && then();
      }
    });

    const rateToSats = store.exchangeRate[store.primaryCurrency.shorthand || 'sats']['sats'] || 1;

    deposit(store.pubkey, subId, store.receiveDetails, rateToSats);
};

const updateKYCLevel = (pubkey: string | undefined) => {
  const subId = `vu_${APP_ID}`;

  const unsub = subscribeToWallet(subId, {
    onEvent: (_, content) => {
      const kyc = content?.content ? parseInt(content?.content) : 0;

      if (kyc in KYC) {
        updateStore('kyc', () => kyc);
      }
    },
    onNotice: (_, reason) => {
      updateStore('kyc', () => KYC.UNKNOWN);
    },
    onEose: (_) => {
      unsub();
    },
  });

  getUserPremissions(store.pubkey, subId);
}

const getBalance = (pubkey: string | undefined) => {
  const subId = `ba_${APP_ID}`;

  const unsub = subscribeToWallet(subId, {
    onEvent: (_, content) => {
      const balance: WalletBalance = JSON.parse(content.content);

      const amountInSats = satsInBTC * (parseFloat(balance.amount) || 0)

      updateStore('balance', () => amountInSats);
    },
    onNotice: (_, reason) => {
      console.warn('Falied to fetch balance: ', reason);
    },
    onEose: (_) => {
      unsub();
    }
  });

  getWalletBalance(store.pubkey, subId);
}

const getTransactions = (pubkey: string | undefined, limit = 8, nextPage?: boolean) => {
  const subId = `th_${APP_ID}`;

  let since = 0;
  let until = 0;

  if (nextPage) {
    since = store.transactionHistoryRange.until;
    until = store.transactionHistoryRange.since;
  }

  // Requesting the next page with `until` at 0 should be disregarded.
  if (nextPage && until === 0) {
    return;
  }

  const unsub = subscribeToWallet(subId, {
    onEvent: (_, response) => {
      const { kind, content } = response;

      if (kind === Kind.WALLET_TRANSACTIONS) {
        const transactions: Transaction[] = JSON.parse(content || '[]');

        if (nextPage) {
          updateStore('transactionHistory', (ts) => [...ts, ...transactions]);
          return;
        }

        updateStore('transactionHistory', () => [...transactions]);
      }

      if (kind === Kind.PAGE_RANGE) {
        const pageRange: PageRange = JSON.parse(content || '{}');

        updateStore('transactionHistoryRange', () => ({ ...pageRange }));
      }
    },
    onNotice: (_, reason) => {
      console.warn('Falied to fetch transactions: ', reason)
    },
    onEose: (_) => {
      storeTransactionPartners();
      unsub();
    }
  });

  updateStore('isFetchingTransactions', true);
  getTransactionHistory(store.pubkey, subId, since, until, limit, 0);
}

const sendPayment = (address: string, details: PaymentDetailInfo, then?: (success: boolean) => void) => {
  const subId = `wt_${APP_ID}`;

  let success = true

  const unsub = subscribeToWallet(subId, {
    onNotice: (_, reason) => {
      console.warn('Falied to send payment: ', reason);
      success = false;
    },
    onEose: (_) => {
      unsub();
      then && then(success);
    }
  });

  if (address.includes('@')) {
    withdraw(store.pubkey, subId, address, details);
    return;
  }

  if (address.startsWith('lnbc')) {
    withdrawLnbc(store.pubkey, subId, address, details);
    return;
  }
};

const getUserProfile = () => {
  const subId = `up_${APP_ID}`;


  const unsub = subscribeToCache(subId, {
    onEvent: (_, response) => {
      const { content, kind } = response;

      if (kind === Kind.MEDIA_INFO) {
        const mediaInfo: MediaEvent = JSON.parse(content);

        let media: Record<string, MediaVariant[]> = {};

        for (let i = 0;i<mediaInfo.resources.length;i++) {
          const resource = mediaInfo.resources[i];
          media[resource.url] = resource.variants;
        }

        updateStore('media', () => ({ ...media }));
      }

      if (kind === Kind.METADATA) {
        const profile = JSON.parse(content || '{}') as UserProfile;

        updateStore('userProfile', () => ({ ...profile }));
      }
    },
    onNotice: (_, reason) => {
      console.warn('Falied to get user profile: ', reason);
    },
    onEose: (_) => {
      unsub();
    }
  });

  getUserMetadata(store.pubkey, subId);
};

let stopMonitoringForUpdates = () => {};

const startMonitoringForUpdates = () => {
  const subId = `mn_${APP_ID}`;

  stopMonitoringForUpdates = subscribeToWallet(subId, {
    onEvent: (_, response) => {
      const { kind, content } = response;

      if (kind === Kind.WALLET_BALANCE) {
        const newBalance: WalletBalance = JSON.parse(content || '{}');

        if (newBalance.currency === 'BTC') {
          const value = parseFloat(newBalance.amount) * satsInBTC;

          if (value !== store.balance) {
            updateStore('changeMonitorSwitch', !store.changeMonitorSwitch);
            updateStore('balance', () => value);
          }
        }

        if (newBalance.currency === 'sats') {
          const value = parseFloat(newBalance.amount);

          if (value !== store.balance) {
            updateStore('changeMonitorSwitch', !store.changeMonitorSwitch);
            updateStore('balance', () => value);
          }
        }

        if (newBalance.currency === 'USD') {
          const value = parseFloat(newBalance.amount) * store.exchangeRate['USD']['sats'];

          if (value !== store.balance) {
            updateStore('changeMonitorSwitch', !store.changeMonitorSwitch);
            updateStore('balance', () => value);
          }
        }
      }
    },
    onNotice: (_, reason) => {
      console.warn('Falied to get update: ', reason);
    },
    onEose: (_) => {
      // stopMonitoringForUpdates();
    }
  });

  startMonitoring(store.pubkey, subId);
}

// EFFECTS --------------------------------------

onMount(() => {
  // Wait for a second before fetching the pubkey, to allow for the extension to initialize
  setTimeout(() => {
    fetchNostrKey();
  }, 1_000);
});

onMount(() => {
  // Expose the wallet store
  (window as NostrWindow).walletStore = store;
});

createEffect(() => {
  // Update KYC level of the account any time pubkey changes as a result of a lookup

  if (store.isKeyLookupDone && store.pubkey) {
    updateKYCLevel(store.pubkey);
    getUserProfile();
    startMonitoringForUpdates();
  }
});

createEffect(() => {
  if (store.kyc > KYC.UNKNOWN) {
    fetchExchangeRate();
    getBalance(store.pubkey);
  }
})

// STORE ----------------------------------------

  const [store, updateStore] = createStore<AccountContextStore>({
    ...initialData,
    primaryAmount,
    secondaryAmount,
    balanceForDisplay,
    currencyExchage,
    getAvatar,
    actions: {
      reverseCurrencies,
      getTransactions,
      sendPayment,
      updateReceiveDetails,
      doDeposit,
      resetReceiveData,
      clearTransactions,
      setIsTransactionFetching,
      startMonitoringForUpdates,
      stopMonitoringForUpdates,
    },
  });

// RENDER ---------------------------------------

  return (
    <AccountContext.Provider value={store}>
      {props.children}
    </AccountContext.Provider>
  );
}

export const useAccountContext = () => useContext(AccountContext);
