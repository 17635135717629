import { Currency } from "./types";
// @ts-ignore
import { generatePrivateKey } from 'nostr-tools';

export const APP_ID = generatePrivateKey();

export const USD: Currency = {
  symbol: '$',
  name: 'United States Dollar',
  shorthand: 'USD',
};

export const satoshi: Currency = {
  symbol: '',
  name: 'Satoshi',
  shorthand: 'sats',
}

export const BTC: Currency = {
  symbol: '',
  name: 'Bitcoin',
  shorthand: 'BTC',
}

export const currencies: Record<string, Currency> = {
  USD,
  BTC,
  sats: satoshi,
};

export const satsInBTC = 100_000_000;

export const exchangeRates: Record<string, Record<string, number>> = {
  USD: {
    sats: 2563.895486936,
  },
  sats: {
    USD: 0.000390031,
  }
}

export const floatingPoints: Record<string,number> = {
  sats: 0,
  USD: 2,
  _: 2,
};
