import { Component, JSXElement } from 'solid-js';

import styles from './BigTextBanner.module.scss';

const BigTextBanner: Component<{ children?: JSXElement }> = (props) => {

  return (
    <div class={styles.bigTextBanner}>
      {props.children}
    </div>
  );
}

export default BigTextBanner;
