import { sendMessage } from "./socketsCache";


export const getUserMetadata = (pubkey: string | undefined, subid: string) => {
  if (!pubkey) {
    return;
  }
  sendMessage(JSON.stringify([
    "REQ",
    subid,
    {cache: ["user_profile", { pubkey }]},
  ]));
}

export const getUserProfiles = (pubkeys: string[], subid: string) => {
  sendMessage(JSON.stringify([
    "REQ",
    subid,
    {cache: ["user_infos", { pubkeys }]},
  ]));
}
