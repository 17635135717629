import { Outlet } from '@solidjs/router';
import { Component, Match, Switch } from 'solid-js';
import BigTextBanner from '../components/UI/BigTextBanner';
import Loader from '../components/UI/Loader';
import ScreenCenter from '../components/UI/ScreenCenter';

import { useAccountContext } from '../contexts/AccountContext';
import { KYC } from '../types.d';

const Layout: Component = () => {
  const account = useAccountContext();

  return (
    <div style="position: relative; height: 100%;">
      <Switch fallback={<ScreenCenter><Loader /></ScreenCenter>}>
        <Match when={!account?.isKeyLookupDone} >
          <ScreenCenter><Loader /></ScreenCenter>
        </Match>

        <Match when={!account?.pubkey} >
          <BigTextBanner>
            <span>No pubkey found.</span>
            <span>Do you have a Nostr extension?</span>
          </BigTextBanner>
        </Match>

        <Match when={account && account.kyc === KYC.UNKNOWN} >
          <BigTextBanner>
            <span>Who are you?</span>
          </BigTextBanner>
        </Match>

        <Match when={account && account.kyc > KYC.UNKNOWN} >
          <Outlet/>
        </Match>
      </Switch>
    </div>
  );
};

export default Layout;
