import { Component, lazy, onCleanup, onMount } from 'solid-js';

import styles from './App.module.css';
import { AccountProvider } from './contexts/AccountContext';
import Layout from './pages/Layout';
import { connect as walletConnect, disconnect as walletDisconnect } from './socketsWallet';
import { connect as cacheConnect, disconnect as cacheDisconnect } from './socketsCache';
import { Route, Routes } from '@solidjs/router';


const App: Component = () => {
  onMount(() => {
    walletConnect();
    cacheConnect();
  });

  onCleanup(() => {
    walletDisconnect();
    cacheDisconnect();
  });

  const Home = lazy(() => import('./pages/Home'));
  const Pay = lazy(() => import('./pages/Pay'));
  const Receive = lazy(() => import('./pages/Receive'));
  const Cashier = lazy(() => import('./pages/Cashier'));
  const Transactions = lazy(() => import('./pages/Transactions'));

  return (
    <AccountProvider>
      <div class={styles.App}>
        <div class="mainContainer">
          <div class="topOfScreen"></div>
          <div class="mainContent">
            <Routes>
              <Route path="/" component={Layout}>
                <Route path="/" element={<Home/>} />
                <Route path="/pay" element={<Pay/>} />
                <Route path="/receive" element={<Receive/>} />
                <Route path="/cashier" element={<Cashier/>} />
                <Route path="/transactions" element={<Transactions/>} />
              </Route>
            </Routes>
          </div>
          <div class="bottonOfScreen"></div>
        </div>
      </div>
    </AccountProvider>
  );
};

export default App;
